
import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import SectionTitle from '../../components/SectionTitle'

import vec1 from '../../images/rsvp/flower1.png'
import vec2 from '../../images/rsvp/flower2.png'

import shape1 from '../../images/rsvp/shape1.png'
import shape2 from '../../images/rsvp/shape2.png'
import AppConfig from '../../api/config';
import Donate from './donate';
import HotelSection from './hotels';
import { storeObjectToFile } from '../../api/controller';
import { useGlobalState } from '../../data/globalState';

const RSVPCUSTOM = (props) => {
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(null);
    const { state, dispatch } = useGlobalState();

    const setOpen = (value) => {
        dispatch({
            type: "OPEN_WELCOME_MODAL",
            payload: value
        })
    }
    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const [forms, setForms] = useState({
        name: '',
        email: '',
        address: '',
        meal: '',
        attend: '',
        guest: '',
        currency: ''
    });
    const [response, setResponse] = useState({
        attending: false,
        fromNigeria: false,
        specialAccom: false,
        currentStep: 0
    })
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })

        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {

        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();

            storeObjectToFile({ ...forms, ...response });
            handleClose()
        } else {
            validator.showMessages();
        }
    };
    const updateStep = (step, reply) => {

        if (reply == null) {
            props.handlClose();
            return
        }
        //check what step and update the response on state.
        if (step == 1) {
            setResponse((resp) => ({ ...resp, attending: reply, currentStep: step }))
        }
        else if (step == 2) {
            setResponse((resp) => ({ ...resp, fromNigeria: reply, currentStep: step }))
        }
        else if (step == 3) {
            setResponse((resp) => ({ ...resp, specialAccom: reply, currentStep: step }))
        }
        else if (step == 4) {

        } else {
            setResponse((resp) => ({
                attending: false,
                fromNigeria: false,
                specialAccom: false, currentStep: 0
            }))

        }
    }


    const FinalFormField = ({ donate = false }) => {
        return <>
            <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >

                {response.specialAccom && <div className="alert alert-secondary alert-sm m-5 w-50 m-auto mt-3" >
                    <div className='row'>
                        <div className='col'>
                            <p className='text-secondary'>Selected Hotel</p>
                        </div>
                    </div>
                    {selected != null && <p className='text-dark'>{AppConfig.hotels[selected].room} Rooms - N{AppConfig.hotels[selected].price}                <div className='text-danger btn btn-sm ml-5' onClick={() => setSelected(null)}>Clear</div></p>}
                </div>}
                <div className="form-field">
                    <input
                        value={forms.name}
                        type="text"
                        name="name"
                        // onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        className="form-control"
                        placeholder="Your Name" />
                    {validator.message('name', forms.name, 'required|alpha_space')}
                </div>
                <div className="form-field">
                    <input
                        value={forms.email}
                        type="email"
                        name="email"
                        // onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        className="form-control"
                        placeholder="Your Email" />
                    {validator.message('email', forms.email, 'required|email')}
                </div>

                {donate == false && <div className="form-field">
                    <select
                        // onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.guest}
                        type="text"
                        className="form-control"
                        name="guest">
                        <option>Number Of Guests</option>
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                    </select>
                    {validator.message('guest', forms.guest, 'required')}
                </div>}
                {/* <div className="form-field">
                                        <input
                                            value={forms.attend}
                                            type="text"
                                            name="attend"
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                            className="form-control"
                                            placeholder="What Will You Be Attending" />
                                        {validator.message('attend', forms.attend, 'required')}
                                    </div> */}





                {donate == true ?

                    <div className="submit-area">
                        <Donate paymentDone={submitHandler}
                            {...forms} purpose='Donate' />
                    </div>
                    :
                    selected != null ? <div className="submit-area">
                        <Donate inputAmount={AppConfig.hotels[selected].price} buttonText="Checkout" paymentDone={submitHandler}
                            {...forms} purpose='Accomodation' />
                    </div> :
                        <div className="submit-area">
                            <button type="submit" className={`theme-btn ${loading ? 'disabled' : ''}`} >Submit Now</button>
                        </div>}
            </form >
        </>
    }

    return (
        <section className={`wpo-contact-section ${props.pt}`} id="RSVPCUSTOM">
            <div className="container ">
                <div className="wpo-contact-section-wrapper "  >
                    <div className="wpo-contact-form-area " >
                        {response.currentStep == 0 && <>
                            <SectionTitle MainTitle={'Are you attending?'} />
                            <div className="row align-items-center">
                                <div className="col col-lg-12 col-12 order-lg-1 order-2 text-holder left-text">
                                    <div className="story-text right-align-text fadeInLeftSlow" data-wow-duration="2000ms">

                                        <span className="date">{AppConfig.welcomeModal.isAttending}</span>
                                        <div className="line-shape s2">
                                            <div className="outer-ball">
                                                <div className="inner-ball"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='row'>
                                <div className="submit-area col">
                                    <button type="button" onClick={() => updateStep(1, true)} className="theme-btn bg-success">Yes</button>
                                </div>
                                <div className="submit-area col">
                                    <button type="button" onClick={() => updateStep(1, false)} className="theme-btn">No</button>
                                </div>
                            </div> </>}
                        {response.currentStep == 1 && <>
                            {response.attending ? <><SectionTitle MainTitle={'Are you Coming from Nigeria?'} />

                                <div className="row align-items-center">
                                    <div className="col col-lg-12 col-12 order-lg-1 order-2 text-holder left-text">
                                        <div className="story-text right-align-text fadeInLeftSlow" data-wow-duration="2000ms">
                                            <span className="date">{AppConfig.welcomeModal.attendanceConfirmed}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="submit-area col">
                                        <button type="button" onClick={() => updateStep(2, true)} className="theme-btn bg-success">Yes</button>
                                    </div>
                                    <div className="submit-area col">
                                        <button type="button" onClick={() => updateStep(2, false)} className="theme-btn">No</button>
                                    </div>
                                </div></>
                                : <>
                                    <SectionTitle MainTitle={'Oops!!'} />
                                    <div className="row align-items-center">
                                        <div className="col col-lg-12 col-12 order-lg-1 order-2 text-holder left-text">
                                            <div className="story-text right-align-text fadeInLeftSlow" data-wow-duration="2000ms">

                                                <span className="date">{AppConfig.welcomeModal.noAttending}</span>
                                                <div className="line-shape s2">
                                                    <div className="outer-ball">
                                                        <div className="inner-ball"></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <FinalFormField donate={true} />

                                        <div className="submit-area col">
                                            <button type="button" onClick={() => handleClose()} className="btn text-danger">Cancel</button>
                                        </div>
                                    </div>
                                </>}
                        </>}
                        {response.currentStep == 2 && <>
                            {<>
                                <SectionTitle MainTitle={'Special Accommodation?'} />
                                <div className="row align-items-center">
                                    <div className="col col-lg-12 col-12 order-lg-1 order-2 text-holder left-text">
                                        <div className="story-text right-align-text fadeInLeftSlow" data-wow-duration="2000ms">

                                            <span className="date">{AppConfig.welcomeModal.notFromNigeria}</span>
                                            <div className="line-shape s2">
                                                <div className="outer-ball">
                                                    <div className="inner-ball"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className='row'>

                                    <div className="submit-area col">
                                        <button type="button" onClick={() => updateStep(3, true)} className="theme-btn bg-success">Yes</button>
                                    </div>

                                    <div className="submit-area col">
                                        <button type="button" onClick={() => updateStep(3, false)} className="theme-btn">No</button>
                                    </div>
                                </div>
                            </>}
                        </>}
                        {response.currentStep == 3 && <>
                            {response.specialAccom ? <><SectionTitle MainTitle={'Great!!!?'} />

                                <div className="row align-items-center">
                                    <div className="col col-lg-12 col-12 order-lg-1 order-2 text-holder left-text">
                                        <div className="story-text right-align-text fadeInLeftSlow" data-wow-duration="2000ms">
                                            <span className="date">{AppConfig.welcomeModal.attendFromNigeria}</span>
                                        </div>
                                    </div>

                                </div>
                                <HotelSection setSelected={setSelected} selected={selected} />
                                <FinalFormField />


                            </>
                                : <>
                                    <SectionTitle MainTitle={'Great!!!?'} />
                                    <div className="row align-items-center">
                                        <div className="col col-lg-12 col-12 order-lg-1 order-2 text-holder left-text">
                                            <div className="story-text right-align-text fadeInLeftSlow" data-wow-duration="2000ms">

                                                <span className="date">Please proceed to fill out the RSVP details. Let us know your name and if you'll be bringing a guest. Thank you!</span>
                                                <div className="line-shape s2">
                                                    <div className="outer-ball">
                                                        <div className="inner-ball"></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <FinalFormField />
                                </>}
                        </>}
                        <div className='row justify-content-center align-items-center mt-5 '>
                            <div onClick={() => updateStep(0, false)} className='col text-center btn btn-sm'>Refresh</div>
                        </div>

                    </div>
                    <div className="vector-1">
                        <img src={vec1} alt="" />
                    </div>
                    <div className="vector-2">
                        <img src={vec2} alt="" />
                    </div>
                </div>

            </div>
            <div className="shape-1">
                <img src={shape1} alt="" />
            </div>
            <div className="shape-2">
                <img src={shape2} alt="" />
            </div>
        </section>
    )
}
export default RSVPCUSTOM;
