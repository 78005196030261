import React, { useEffect, useState } from 'react';
import { FlutterWaveButton, closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import AppConfig from '../../api/config';

export default function Donate({ inputAmount, buttonText = null, paymentDone = () => { }, email = 'theoxygen@gmail.com', phone = '', name = '', purpose = '' }) {
    const [amount, setAmount] = useState(inputAmount)
    const [payBy, setPayBy] = useState(false)




    const config = {
        public_key: "FLWPUBK-2fb0df460c91a31b925ed5a01972c51c-X",
        tx_ref: "titanic-48981487343MDI0NzMx",
        amount: amount,
        currency: "NGN",
        payment_options: "card, mobilemoneyghana, ussd",
        redirect_url: AppConfig.appUrl,
        meta: {
            purpose: purpose,
        },
        customer: {
            email: email,
            phone_number: phone,
            name: name,
        },
        customizations: {
            title: purpose,
            description: "The Oxygen Day",
            logo: "https://test.trashcoin.eu/static/media/shape.bb9d74528ad7c0bc21d42e529d1bd68e.svg",
        }
    };

    const fwConfig = {
        ...config,
        text: 'Pay with Flutterwave!',

    };
    const handleFlutterPayment = useFlutterwave(config);

    return (

        <>

            <div className='row w-75 m-auto' style={{ alignContent: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div className='submit-area col'>
                    <a href="https://www.paypal.me/NnodimEliotWogu?locale.x=en_DE" className='theme-btn bg-white border rectangle-4 w-75' >
                        <div className='row'><img  src='https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-mark-color.svg' height={20} /></div>
                    </a>
                </div>
                <div className="submit-area col">
                    <button type="button" onClick={() => setPayBy((val) => !val)} className="theme-btn bg-success">{buttonText != null ? 'Checkout (Naira)' : 'Donate (Naira)'}</button>
                </div>
            </div>


            {payBy && <><div className="form-field mt-5 " style={{ alignContent: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', }} >

                <div className='row m-auto w-75' >
                    <input
                        value={amount}
                        type="number"
                        name="amount"
                        onBlur={(e) => { }}
                        onChange={(e) => setAmount(e.target.value)}
                        className="form-control w-75"
                        placeholder="Enter Amount (₦)" />

                    <button type="button" onClick={() => {
                        if (amount == null || name == '' || email == '') {
                            alert("Please provide email and name to proceed");
                            return 
                        }
                        handleFlutterPayment({
                            callback: (response) => {
                                paymentDone();
                                console.log(response);
                                closePaymentModal() // this will close the modal programmatically
                            },
                            onClose: () => { },
                        })
                    }} className="btn btn-sm bg-white border  w-25">Proceed</button>

                </div>
            </div> </>}

        </>

    );
}


